<template>
  <div>
    <b-card
      v-if="$store.state.auth.role === 1"
      no-body
    >
      <b-card-header>
        <h5>Processus de fabrication</h5>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover
          title="Ajouter"
          variant="outline-primary"
          :to="{ name: 'manufacturings-create' }"
        >
          Ajouter
        </b-button>
      </b-card-header>
      <b-row>
        <b-col
          cols="12"
        >
          <b-table
            v-if="manufacturingsList.length > 0"
            class="position-relative"
            :items="manufacturingsList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Aucun processus trouvé"
            :sort-desc.sync="isSortDirDesc"
          >
            <template #cell(createdAt)="data">
              <b-link
                :to="{ name: 'manufacturings-view', params: { id: data.item.id } }"
                class="font-weight-bold d-block link-hover"
              >
                {{ data.item.device_serialnumber }}
              </b-link>
            </template>

            <template #cell(statut)="data">
              <b-badge
                :variant="`light-${resolveActiveVariant(data.item.manufacturingValidation)}`"
                pill
              >
                {{ data.item.manufacturingValidation ? 'Terminé' : 'En cours' }}
              </b-badge>
            </template>

            <template #cell(action)="data">
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                v-b-tooltip.hover
                title="Modifier"
                variant="flat-danger"
                class="btn-icon rounded-circle"
              >
                <b-link
                  :to="{ name: 'manufacturings-edit', params: { id: data.item.id }}"
                >
                  <feather-icon
                    icon="SettingsIcon"
                    size="20"
                  />
                </b-link>
              </b-button>
            </template>
          </b-table>
          <div
            v-else
            class="text-center"
          >
            <b-spinner
              v-if="isLoading"
              class="mt-5 mb-5"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BTable, BLink, VBTooltip,
  BBadge, BCardHeader, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BLink,
    BBadge,
    BCardHeader,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      manufacturingsList: [],
      tableColumns: [
        { key: 'createdAt', label: 'Device', sortable: true },
        { key: 'head_serialnumber', label: 'Tête', sortable: true },
        { key: 'order', label: 'Commande', sortable: true },
        { key: 'statut', label: 'Statut', sortable: true },
        {
          key: 'action', label: 'Action', sortable: false, thClass: 'text-center', tdClass: 'text-center',
        },
      ],
      sortBy: 'createdAt',
      isSortDirDesc: true,
    }
  },
  created() {
    this.getManufacturings()
  },
  methods: {
    getManufacturings() {
      this.isLoading = true
      store.dispatch('manufacturing/findAllManufacturings')
        .then(response => {
          this.manufacturingsList = response
          this.isLoading = false
        })
        .catch(err => {
          console.log('err', err)
          this.manufacturingsList = []
          this.isLoading = false
        })
    },
    resolveActiveVariant(data) {
      if (data) return 'success'
      if (!data) return 'warning'
      return 'dark'
    },
  },
}
</script>
